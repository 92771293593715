<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		class="w-100 cart"
		:style="themeColors">
		<div
			:style="{ marginTop: topOffset + 'px' }"
			:class="['xs','sm'].includes(windowWidth) ? 'h-80' : 'h-55'" />
		<div
			:class="['xs','sm'].includes(windowWidth) ? 'py-5' : 'p-5'"
			class="border-0 bg-white mb-0">
			<!-- Back to store link -->
			<div
				class="row mx-auto mb-5 container">
				<div class="col-12">
					<a @click="$router.push({ name: getRedirectName('Store') })">
						<div class="btn btn-link pointer text-decoration-none p-0">
							<div class="branded-text">
								<i class="fas fa-chevron-left mr-2" />
								<span class="font-weight-bold">{{ translate('back_to_store') }}</span>
							</div>
						</div>
					</a>
				</div>
			</div>
			<!-- Main product -->
			<div v-if="mainProductLoading">
				<is-loading
					:loading="mainProductLoading"
					:loading-label="translate('loading')"
					:no-data-label="translate('data_not_found')" />
			</div>
			<div v-else>
				<!-- Image / data -->
				<div
					:class="['xs','sm', 'md'].includes(windowWidth) ? ' px-3' : 'px-5'"
					class="row mx-auto container mb-5">
					<!-- Image Component -->
					<div
						class="col-12 col-md-6"
						:style="['xs', 'sm'].includes(windowWidth) ? 'display: none': ''"
						:class="['xs','sm', 'md'].includes(windowWidth) ? ' px-3' : 'px-5'">
						<image-carousel
							modal-id="desktop"
							:images="imgCarousel"
							:group-to-show="getGroupToShow"
							style="--product-primary-color: var(--theme-primary-color);"
							highlight-color="var(--theme-primary-color)"
							:enable-zoom="true" />
					</div>
					<!-- Product price/sizes/colors/qty -->
					<div class="col-12 col-md-6">
						<!-- Product name / price -->
						<p
							class="h1 font-weight-bold branded-text"
							:class="{ 'm-0': subtitle().length > 0 }">
							{{ computedTitle }}
						</p>
						<p class="h4 text-uppercase font-weight-bold branded-text">
							{{ subtitle() }}
						</p>
						<!-- Prices -->
						<p>
							<s
								v-if="computedPromoPrice.length > 0"
								class="h5 font-weight-bold text-muted"
								style="opacity: 0.7">
								{{ computedPrice }}
							</s>
							<template v-else-if="feesInfo && feesInfo.show_fee">
								<template v-if="feesInfo.show_only_text">
									<span
										class="h3 font-weight-bold branded-text">
										{{ feesInfo.price_with_fee.formatted_amount }}
									</span>
									<span class="text-muted">
										{{ translate(`plus_${feesInfo.code_name}`) }}
									</span>
								</template>
								<template v-else>
									<span
										class="h3 font-weight-bold branded-text">
										{{ feesInfo.price_with_fee.formatted_amount }}
									</span>
									<p class="text-muted mb-0">
										{{ computedPrice }} {{ renderDefaultFee(feesInfo.default) }}
										<i
											v-if="feesInfo.variations.length"
											v-b-tooltip.hover.top
											:title="feeVariationsText(feesInfo.variations)"
											class="fas fa-info-circle" />
									</p>
									<p
										v-for="(fee, index) in feesInfo.whole_cart"
										:key="index"
										class="text-muted">
										{{ translate(`plus_fee_whole_cart_${fee.operator}`, { fee: fee.formatted_amount, qty: fee.qty }) }}
									</p>
								</template>
							</template>
							<template v-else>
								<span
									class="h3 font-weight-bold branded-text">
									{{ computedPrice }}
								</span>
							</template>
						</p>
						<p
							v-if="computedPromoPrice.length > 0">
							<span class="h3 font-weight-bold branded-text">
								{{ computedPromoPrice }}
							</span>
						</p>
						<div class="h6 text-muted">
							{{ computedPriceInfo }}
						</div>
						<div
							v-if="mainHasExchange"
							class="mx-auto exchange-text text-center medium d-flex align-items-end">
							{{ mainExchangePrice }}*
						</div>
						<div
							v-if="mainHasExchange"
							class="mx-auto exchange-text text-left small d-flex align-items-end">
							{{ translate('exchange_disclaimer') }}
						</div>
						<p class="text-muted h6 font-weight-bold">
							{{ computedPresentation }}
						</p>
						<div
							v-if="showReviews"
							style="cursor: pointer"
							@click="goToReviewsTab()">
							<b-rating
								v-if="productReviewsSummaryCache[getProductId] && productReviewsSummaryCache[getProductId].total > 0"
								:value="productReviewsSummaryCache[getProductId] ? productReviewsSummaryCache[getProductId].average_rating : 0"
								variant="primary"
								style="padding-left: 0; padding-top: 0; padding-bottom: 0"
								color="var(--theme-primary-color)"
								inline
								no-border
								show-value-max
								show-value
								precision="1"
								readonly />
							<span
								class="text-muted text-medium">
								{{
									productReviewsSummaryCache[getProductId] && productReviewsSummaryCache[getProductId].total > 0
										? translate('based_on_x_reviews', { x: productReviewsSummaryCache[getProductId] ? productReviewsSummaryCache[getProductId].total : 0 })
										: translate('no_reviews_yet')
								}}
							</span>
						</div>
						<hr>
						<div :style="!['xs', 'sm'].includes(windowWidth) ? 'display: none': ''">
							<image-carousel
								modal-id="mobile"
								:images="imgCarousel"
								:group-to-show="getGroupToShow"
								:enable-zoom="true" />
						</div>
						<!-- Color -->
						<option-select
							variant="color"
							:title="translate('color')"
							:options="getProductColors"
							:selected-option.sync="selectedProduct.color"
							:initial-selected-option="selectedProduct.color" />
						<!-- Size -->
						<option-select
							variant="size"
							:title="translate('size')"
							:options="getProductSizes"
							:selected-option.sync="selectedProduct.size"
							:initial-selected-option="selectedProduct.size" />
						<!-- Formats -->
						<option-select
							:title="translate('format')"
							:options="getProductFormats"
							:selected-option.sync="selectedProduct.format"
							:initial-selected-option="selectedProduct.format" />
						<!-- Event Type -->
						<option-select
							:title="translate('event_type')"
							:options="getEventType"
							:selected-option.sync="selectedProduct.event_type"
							:initial-selected-option="selectedProduct.event_type">
							<!-- <div class="row no-gutters">
								<div class="col">
									<p class="mt-2 mb-0 font-weight-bolder text-danger">
										{{ translate('ticket_not_refundable_disclaimer') }}
									</p>
								</div>
							</div> -->
						</option-select>
						<!-- Duration -->
						<option-select
							:title="translate('duration')"
							:options="getDuration"
							:selected-option.sync="selectedProduct.duration"
							:initial-selected-option="selectedProduct.duration">
							<div class="row no-gutters">
								<div class="col">
									<p class="mt-2 mb-0 font-weight-bolder text-danger">
										{{ translate('ticket_not_refundable_disclaimer') }}
									</p>
								</div>
							</div>
						</option-select>
						<!-- Presentation -->
						<option-select
							:title="translate('presentations')"
							:options="getPresentations"
							:selected-option.sync="selectedProduct.presentations"
							:initial-selected-option="selectedProduct.presentations" />
						<!-- Flavors -->
						<option-select
							:title="translate('flavor')"
							:options="getFlavor"
							:selected-option.sync="selectedProduct.flavor"
							:initial-selected-option="selectedProduct.flavor" />
						<!-- Material Types -->
						<option-select
							:title="translate('material_type')"
							:options="getMaterialTypes"
							:selected-option.sync="selectedProduct.material_type"
							:initial-selected-option="selectedProduct.material_type" />
						<!-- Combinations -->
						<option-select
							:title="translate('flavor')"
							:options="getCombination"
							:selected-option.sync="selectedProduct.combination" />
						<!-- Combos -->
						<multiple-option-select
							:multiple-options="multipleOptions"
							:required-select="requiredSelect"
							:selection.sync="selectedProduct.combo"
							:mandatory-selection="['PL']" />
						<div
							v-if="['TK-VIT24-UD', 'TK-VIT24-US'].includes(getGroupToShow)"
							class="row my-3">
							<div class="col">
								<div class="row no-gutters">
									<div class="col">
										<b-alert
											show
											variant="green"
											class="mb-0 text-dark">
											<div
												v-if="getGroupToShow === 'TK-VIT24-UD'"
												v-html="translate('tk_vit_24_ud_info_actual')" />
											<div
												v-else-if="getGroupToShow === 'TK-VIT24-US'"
												v-html="translate('tk_vit_24_us_info_actual')" />
										</b-alert>
									</div>
								</div>
							</div>
						</div>
						<div
							v-if="['TK-VIT24-CO', 'TK-VIT24-SI', 'TK-VIT24-UD', 'TK-VIT24-US'].includes(getGroupToShow)"
							class="row my-3">
							<div class="col">
								<div class="row no-gutters">
									<div class="col">
										<b-alert
											show
											variant="info"
											class="mb-0 text-dark">
											<div
												v-if="getGroupToShow === 'TK-VIT24-CO'"
												v-html="translate('tk_vit_24_co_info')" />
											<div
												v-else-if="getGroupToShow === 'TK-VIT24-SI'"
												v-html="translate('tk_vit_24_si_info')" />
											<div
												v-else-if="getGroupToShow === 'TK-VIT24-UD'"
												v-html="translate('tk_vit_24_ud_info')" />
											<div
												v-else-if="getGroupToShow === 'TK-VIT24-US'"
												v-html="translate('tk_vit_24_us_info')" />
										</b-alert>
									</div>
								</div>
							</div>
						</div>
						<!-- Individual Purchase -->
						<div
							v-if="isForIndividualPurchase"
							class="row my-3">
							<div class="col">
								<div class="row no-gutters">
									<div class="col">
										<b-alert
											show
											variant="warning"
											class="mb-0 text-dark">
											{{ translate('product_for_individual_sale') }}
										</b-alert>
									</div>
								</div>
							</div>
						</div>
						<!-- Not Individual Purchase -->
						<div
							v-if="isNotForIndividualPurchase"
							class="row my-3">
							<div class="col">
								<div class="row no-gutters">
									<div class="col">
										<b-alert
											show
											variant="warning"
											class="mb-0 text-dark">
											{{ translate('product_not_for_individual_sale') }}
										</b-alert>
									</div>
								</div>
							</div>
						</div>
						<!-- Shipping Date Alert -->
						<div
							v-if="shippingDateAlert"
							class="row my-3">
							<div class="col">
								<div class="row no-gutters">
									<div class="col">
										<b-alert
											show
											variant="info"
											class="mb-0 text-dark">
											{{ translate('shipping_date_alert') }}
										</b-alert>
									</div>
								</div>
							</div>
						</div>
						<!-- Minimun Purchase alert -->
						<div
							v-if="minPurchaseAlert"
							class="row my-3">
							<div class="col">
								<div class="row no-gutters">
									<div class="col">
										<b-alert
											show
											variant="warning"
											class="mb-0 text-dark">
											{{ minPurchaseAlertMsg }}
										</b-alert>
									</div>
								</div>
							</div>
						</div>
						<!-- Product out of stock -->
						<div
							v-if="productSoldOut"
							class="row">
							<div class="col-md-7 col-sm-12 col-xs-12 align-self-center">
								<button
									class="mb-0 btn btn-block btn-disabled disabled">
									{{ translate(`sold_out`) }}
								</button>
							</div>
						</div>
						<div
							v-if="productOutOfStock && !productSoldOut"
							class="row">
							<div class="col-md-7 col-sm-12 col-xs-12 align-self-center">
								<button
									class="mb-0 btn btn-block btn-disabled disabled">
									{{ translate(`out_of_stock`) }}
								</button>
							</div>
						</div>
						<!-- Combo Requirements -->
						<div
							v-if="isCombo && !isComboComplete"
							class="row mb-3">
							<div class="col">
								<div class="row no-gutters">
									<div class="col">
										<b-alert
											show
											variant="info"
											class="mb-0 text-dark">
											{{ productsMissingForCombo === 1 ? translate('combo_choose_one_more_product') : translate('combo_choose_x_more_product', { qty: productsMissingForCombo }) }}
										</b-alert>
									</div>
								</div>
							</div>
						</div>
						<div
							v-if="$route.params.productName === 'Naara-Beauty-Drink'"
							class="row my-3">
							<div class="col">
								<div class="row no-gutters">
									<div class="col">
										<b-alert
											show
											variant="warning"
											class="mb-0 text-dark">
											{{ translate('naara_disclaimer') }}
										</b-alert>
									</div>
								</div>
							</div>
						</div>
						<div
							v-if="['V-Club-Membership'].includes($route.params.productName)"
							class="row">
							<div
								:class="['xs','sm'].includes(windowWidth) ? 'text-right' : ''"
								class="col align-self-center mb-3">
								<button
									style="font-weight: 600;"
									class="btn p-2 pointer custom-btn custom-primary-btn branded-button"
									@click="$router.push({ name: getRedirectName('Renewal'), query: { membership: 'v_club' } })">
									{{ translate('subscribe_now') }}
								</button>
							</div>
						</div>
						<!-- Product qty/add btn -->
						<div
							v-else-if="!productOutOfStock && !productSoldOut"
							:class="isCombo && !isComboComplete ? '' : 'pt-3'"
							class="row">
							<div class="align-self-center w-auto mb-3">
								<number-input-spinner
									v-model="selectedProduct.quantity"
									:mouse-down-speed="500"
									:min="MIN_QTY"
									:max="setMaxQty"
									:button-class="'vnis__button bg-primary-alt'"
									:input-class="'vnis__input vnis-custom-input-width'"
									:integer-only="true"
									class="vnis-smaller col" />
							</div>
							<div
								:class="['xs','sm'].includes(windowWidth) ? 'text-right' : ''"
								class="col align-self-center mb-3">
								<button
									:class="disableAddBtn ? 'disabled' : ''"
									:disabled="disableAddBtn"
									style="font-weight: 600;"
									class="btn p-2 pointer custom-btn custom-primary-btn branded-button"
									@click="disableAddBtn ? null : addProduct(getProductSku)">
									<template v-if="addProductsLoading">
										<i class="fa fa-fw fa-spinner fa-pulse" /> {{ translate('processing') }}
									</template>
									<template v-else>
										{{ translate('add_to_cart') }}
									</template>
								</button>
							</div>
						</div>
						<div
							v-if="['Lite'].includes($route.params.productName) && !['NZ'].includes(country)"
							class="row">
							<div class="col align-self-center mb-3">
								<button
									:class="addProductsLoading ? 'disabled' : ''"
									:disabled="addProductsLoading"
									class="btn btn-primary p-2 pointer custom-btn custom-primary-btn add-all-options-btn"
									style="font-weight: 600; font-size: 1rem; background-color: #6aa50a; border-width: 0; height: 50px; display: flex; justify-content: center; align-items: center"
									:style="{ width: ['xs','sm'].includes(windowWidth) ? '100%' : '285px' }"
									@click="disableAddBtn ? null : addProduct(getManyProductSku())">
									<template v-if="addProductsLoading">
										<i class="fa fa-fw fa-spinner fa-pulse" /> {{ translate('processing') }}
									</template>
									<template v-else>
										{{ translate('get_all_of_them') }}
									</template>
								</button>
							</div>
						</div>
					</div>
				</div>
				<!-- Tabs -->
				<div
					:class="['xs','sm', 'md'].includes(windowWidth) ? ' px-3' : 'px-5'"
					class="row mx-auto container mb-5">
					<div class="col-12">
						<b-tabs
							id="product-details-tabs"
							v-model="tabIndex">
							<b-tab
								v-for="(tabInfo, index) in computedTabInfo"
								:key="index"
								:title="translate(index)">
								<template v-if="index === 'reviews' && showReviews">
									<b-row>
										<b-col
											v-if="productReviewsCache[getProductId] && productReviewsCache[getProductId].length"
											cols="auto">
											<b-button-group
												class="mb-3">
												<b-button
													v-for="column in orderBy"
													:key="column"
													:class="{
														'branded-button': column === sortCol,
													}"
													@click="sortCol = column;"
													v-text="translate(column)" />
											</b-button-group>
										</b-col>
										<b-col>
											<template v-if="userPendingReview">
												<product-review-modal
													:show.sync="showReviewModal"
													:review-info="reviewInfo"
													action="create"
													@created="getProductReviews(getProductId)" />
												<button
													class="btn branded-button-outline"
													@click="createReview()">
													{{ translate('write_a_review') }}
													<i class="fas fa-edit" />
												</button>
											</template>
										</b-col>
									</b-row>
									<div
										v-for="(item, reviewIndex) in productReviewsCache[getProductId]"
										:key="reviewIndex"
										class="h-100 d-flex align-items-center"
										:style="reviewIndex === productReviewsCache[getProductId].length - 1 ? '' : 'border-bottom: 1px solid #dee2e6;'">
										<product-review :review="item" />
									</div>
									<b-pagination
										v-if="pagination.total_pages > 1"
										v-model="pagination.current_page"
										:total-rows="pagination.total"
										:per-page="pagination.per_page"
										align="center"
										style="border-bottom: 0"
										@change="getProductReviews(getProductId, $event)" />
									<div
										v-if="productReviewsCache[getProductId] && productReviewsCache[getProductId].length"
										class="p-4 mx-auto text-center text-medium"
										style="background-color: rgb(245, 245, 245); color: rgb(124, 124, 124); max-width: 100%;">
										<sup data-v-8373beb4="">*</sup>
										{{ translate('product_reviews_disclaimer') }}
									</div>
									<template v-if="noProductReviews">
										<p class="text-center m-0 text-muted">
											{{ translate('no_reviews_yet') }}
										</p>
									</template>
								</template>
								<template v-else>
									<!-- eslint-disable-next-line -->
									<p v-html="tabInfo" />
									<div
										v-if="mainProductData.sku === 'TK-VX'"
										class="text-center mt-3">
										<img
											:class="{ 'w-75': !['xs','sm'].includes(windowWidth) }"
											:src="ticketFlyerUrl"
											class="img-fluid mx-auto">
									</div>
								</template>
							</b-tab>
						</b-tabs>
					</div>
				</div>
			</div>
			<!-- Related products -->
			<div v-if="$user.auth()">
				<div class="mx-auto container mt-5 mb-5">
					<hr>
				</div>
				<div v-if="mainProductLoading || relatedProductsLoading">
					<is-loading
						:loading="mainProductLoading || relatedProductsLoading"
						:loading-label="translate('loading')"
						:no-data-label="translate('data_not_found')" />
				</div>
				<div
					v-else
					:class="['xs','sm', 'md'].includes(windowWidth) ? ' px-3' : 'px-5'"
					class="row mx-auto container">
					<div class="col-12 text-center">
						<p class="text-muted h2 bold-text">
							{{ translate('related_products') }}
						</p>
					</div>
					<div
						class="py-4 w-100"
						:class="['xs','sm'].includes(windowWidth) ? 'scrolling-wrapper' : 'row'">
						<div
							v-for="(item, index) in relatedProductsData"
							:key="index"
							class="text-center my-3"
							:class="['xs','sm'].includes(windowWidth) ? 'd-inline-block' : 'col-md'">
							<img
								:src="item.image"
								:class="['xs','sm'].includes(windowWidth) ? 'img-fluid ' : 'img-max-width'"
								class="mx-auto mb-3 px-4 pointer"
								@click="$router.push({ name: getRedirectName('ProductDetails'), params: { productName: item.product_name } })">
							<div class="mt-2">
								<p class="h6 text-muted bolder-title">
									{{ translate(item.code_name) }}
								</p>
								<p class="h4">
									{{ getProductPrice(item) }}
								</p>
								<!-- <p
									v-if="item.exchange"
									class="exchange-text h6">
									{{ item.exchange_price }}
								</p> -->
								<button
									type="button"
									:class="['md'].includes(windowWidth) ? ' w-75' : 'w-50'"
									class="btn btn-rounded mt-3 branded-button-outline"
									@click="$router.push({ name: getRedirectName('ProductDetails'), params: { productName: item.product_name } })">
									{{ translate('see_more') }}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import cookie from 'vue-cookie';
import NumberInputSpinner from 'vue-number-input-spinner';
import WindowSizes from '@/mixins/WindowSizes';
import AgencySite from '@/mixins/AgencySite';
import { MIN_QTY, MAX_QTY } from '@/settings/Cart';
import { NOT_FOUND } from '@/settings/Errors';
import {
	NON_AUTH_HIDDEN_PRODUCTS,
	SORTED_PRODUCT_SIZES,
	PRODUCT_DEFAULT_PRICE,
	PRODUCT_OVERRIDE_PRICE,
	PRODUCT_MAX_QTY,
	PRODUCT_SKU_MAX_QTY,
	ALLOWED_PRODUCTS_TO_REVIEW,
	PRODUCT_THEME,
} from '@/settings/Products';
import { META_DESCRIPTION } from '@/settings/Meta';
import {
	Store, Products as ProductsTranslations, Events, ProductReviews as ProductReviewsMessages,
} from '@/translations';
import Cart from '@/util/Cart';
import EventBus from '@/util/eventBus';
import { trackEvent } from '@/util/GoogleTagManager';
import Products from '@/util/Products';
import isLoading from '@/components/Loading';
import ImageCarousel from '@/components/ProductImageCarousel';
import OptionSelect from '@/components/OptionSelect';
import MultipleOptionSelect from '@/components/MultipleOptionSelect';
import EarlyLaunch from '@/mixins/EarlyLaunch';
import ProductReviews from '@/util/ProductReviews';
import ProductReview from '@/components/ProductReview/index.vue';
import PublicStore from '@/mixins/PublicStore';
import ProductReviewModal from '@/views/ProductReviews/components/ProductReviewModal.vue';

export default {
	name: 'ProductDetails',
	metaInfo() {
		const content = META_DESCRIPTION[`product_detail_${this.$route.params.productName.toLowerCase()}`];
		if (content !== undefined) {
			return {
				meta: [
					{ vmid: 'description', name: 'description', content },
				],
			};
		}
		return {};
	},
	messages: [Store, ProductsTranslations, Events, ProductReviewsMessages],
	components: {
		ProductReview,
		OptionSelect,
		NumberInputSpinner,
		isLoading,
		ImageCarousel,
		ProductReviewModal,
		MultipleOptionSelect,
	},
	mixins: [WindowSizes, EarlyLaunch, PublicStore, AgencySite],
	data() {
		return {
			NOT_FOUND,
			mainProduct: new Products(),
			relatedProducts: new Products(),
			comboProduct: new Products(),
			addProducts: new Cart(),
			alert: new this.$Alert(),
			SORTED_PRODUCT_SIZES,
			MIN_QTY,
			MAX_QTY,
			PRODUCT_MAX_QTY,
			PRODUCT_SKU_MAX_QTY,
			selectedProduct: {
				quantity: MIN_QTY,
				color: '',
				size: '',
				format: '',
				event_type: '',
				duration: '',
				presentations: '',
				flavor: '',
				combination: '',
				material_type: '',
				combo: [],
			},
			tabIndex: 0,
			ticketFlyerUrl: '',
			ticketFlyerImages: {
				en: 'https://velovita.s3-us-west-1.amazonaws.com/backoffice/assets/VelovitaXLR8.png',
				es: 'https://velovita.s3-us-west-1.amazonaws.com/backoffice/assets/VelovitaXLR8Es.png',
				ja: 'https://velovita.s3-us-west-1.amazonaws.com/backoffice/assets/VelovitaXLR8Ja.png',
			},
			outOfStockProducts: {},
			multipleOptions: [],
			requiredSelect: 0,
			comboLoading: false,
			productReviews: new ProductReviews(),
			productReviewsCache: {},
			productReviewsSummaryCache: {},
			orderBy: ['rating', 'newest'],
			sortCol: 'newest',
			showReviewModal: false,
			reviewInfo: {},
			topOffset: 0,
		};
	},
	computed: {
		showReviews() {
			try {
				return ALLOWED_PRODUCTS_TO_REVIEW.includes(this.$route.params.productName);
			} catch (e) {
				// Do nothing
			}
			return false;
		},
		pagination() {
			return this.productReviews.data.pagination;
		},
		userPendingReview() {
			try {
				return this.productReviewsSummaryCache[this.getProductId].user_pending_review;
			} catch (error) {
				return false;
			}
		},
		noProductReviews() {
			try {
				return this.productReviewsCache[this.getProductId].length === 0;
			} catch (error) {
				return false;
			}
		},
		productFromUrl() {
			try {
				return this.mainProduct.data.response.data.data;
			} catch (e) {
				return {};
			}
		},
		mainProductData() {
			try {
				const data = this.productFromUrl;
				let description = `${data.attributes.code_name}_description`;
				if (data.attributes.selectable) {
					data.attributes.configurations = {};
					description = data.attributes.selectable.default_description ?? description;
				}
				return {
					id: data.attributes.bo_id,
					sku: data.attributes.sku,
					code_name: data.attributes.code_name,
					price: this.getProductPrice(data.attributes),
					image: data.attributes.image,
					presentation: `${data.attributes.code_name}_presentation`,
					children: data.attributes.children,
					thumbnail: data.attributes.thumbnail,
					exchange: data.attributes.exchange,
					exchange_price: data.attributes.exchange_price,
					out_of_stock: data.attributes.out_of_stock,
					description,
					selectable: data.attributes.selectable,
					sold_out: data.attributes.sold_out,
					fees: data.attributes.fees,
				};
			} catch (error) {
				return [];
			}
		},
		mainProductPrice() {
			try {
				if (PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.price) {
					const shownPrice = PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.price;
					if (PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.translate || false) {
						return this.translate(shownPrice);
					}
					return shownPrice;
				}
				if (Object.keys(this.mainProductData).length > 0) {
					const children = this.mainProductData.children[Object.keys(this.getProductSku)[0]];
					if (children.eb_price) {
						return children.eb_price.price;
					}
					return children.price;
				}
				return this.mainProductData.price;
			} catch (e) {
				return this.mainProductData.price;
			}
		},
		mainHasExchange() {
			try {
				if (PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.exchangePrice) {
					return !!PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.exchangePrice;
				}
				if (Object.keys(this.mainProductData).length > 0) {
					return Object.keys(this.mainProductData.children).length ? this.mainProductData.children[Object.keys(this.getProductSku)[0]].exchange : this.mainProductData.exchange;
				}
				return false;
			} catch (e) {
				return false;
			}
		},
		mainExchangePrice() {
			try {
				if (PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.exchangePrice) {
					return PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.exchangePrice;
				}
				if (this.mainHasExchange) {
					return Object.keys(this.mainProductData.children).length ? this.mainProductData.children[Object.keys(this.getProductSku)[0]].exchange_price : this.mainProductData.exchange_price;
				}
				return '';
			} catch (e) {
				return '';
			}
		},
		mainProductLoading() {
			return !!this.mainProduct.data.loading || this.comboLoading;
		},
		getGroupToShow() {
			if (['Combo', 'Tri-Pack', 'Quad-Pack', 'Penta-Pack', 'Plos-Bundle'].includes(this.$route.params.productName)) {
				return this.$route.params.productName;
			}
			return Object.keys(this.getProductSku)[0];
		},
		getProductId() {
			try {
				if (Object.keys(this.mainProductData.children).length > 0) {
					return this.mainProductData.children[this.getGroupToShow].id;
				}
				return this.mainProductData.id;
			} catch (error) {
				return null;
			}
		},
		imgCarousel() {
			try {
				const data = this.productFromUrl;
				let extraImages = data.attributes.extra_images;
				if (Array.isArray(extraImages) && extraImages.length === 0) {
					extraImages = {};
				}

				if (['Combo', 'Tri-Pack', 'Quad-Pack', 'Penta-Pack', 'Plos-Bundle'].includes(data.attributes.decoded_name)) {
					extraImages[data.attributes.decoded_name] = [
						{
							image: data.attributes.image,
							thumbnail: data.attributes.thumbnail,
						},
					];
				}
				return extraImages;
			} catch (error) {
				return {};
			}
		},
		relatedProductsData() {
			try {
				const { data } = this.relatedProducts.data.response.data;
				let response = data.filter((obj) => (this.mainProductData.sku !== obj.attributes.sku)).slice(0, 3);
				response = response.map((obj) => {
					const newObj = {
						sku: obj.attributes.sku,
						code_name: obj.attributes.code_name,
						price: obj.attributes.price,
						image: obj.attributes.image,
						product_name: obj.attributes.decoded_name,
						exchange: obj.attributes.exchange,
						exchange_price: obj.attributes.exchange_price,
					};
					return newObj;
				});
				return response;
			} catch (error) {
				return [];
			}
		},
		relatedProductsLoading() {
			return !!this.relatedProducts.data.loading;
		},
		addProductsLoading() {
			return !!this.addProducts.data.loading;
		},
		getProductColors() {
			try {
				const data = this.productFromUrl;
				const colorInfo = data.attributes.configurations.color;
				const availableColors = Object.keys(colorInfo);
				if (availableColors.length === 0) {
					this.returnToStore();
				}
				return availableColors.map((color) => ({ value: colorInfo[color].code_name }));
			} catch (error) {
				return [];
			}
		},
		getProductSizes() {
			try {
				const data = this.productFromUrl;
				const colorInfo = data.attributes.configurations.color;
				const colorCodeName = Object.keys(colorInfo).find((color) => colorInfo[color].code_name === this.selectedProduct.color);
				const availableSizes = Object.keys(colorInfo[colorCodeName].configurations.size);
				this.sortSizes(availableSizes);
				if (availableSizes.length === 0) {
					this.returnToStore();
				}
				return availableSizes.map((value) => ({ value, text: this.translate(value.toUpperCase()) }));
			} catch (error) {
				return [];
			}
		},
		getProductFormats() {
			try {
				const data = this.productFromUrl;
				const formatsSku = data.attributes.configurations.format;
				let availableFormats = Object.keys(formatsSku);
				if (!this.$user.auth()) availableFormats = availableFormats.filter((item) => !['1003', '1004'].includes(item));
				if (availableFormats.length === 0) {
					this.returnToStore();
				}
				return availableFormats.map((value) => ({ value, text: this.translate(`${value}_format`) }));
			} catch (error) {
				return [];
			}
		},
		getEventType() {
			try {
				const data = this.productFromUrl;
				const availableEvents = Object.keys(data.attributes.configurations.event_type);
				if (availableEvents.length === 0) {
					this.returnToStore();
				}
				return availableEvents.map((value) => ({ value, text: this.translate(`${value}_event_type`) }));
			} catch (error) {
				return [];
			}
		},
		getDuration() {
			try {
				const data = this.productFromUrl;
				const eventInfo = data.attributes.configurations.event_type;
				const eventCodeName = Object.keys(eventInfo).find((type) => type === this.selectedProduct.event_type);
				const availableDuration = Object.keys(eventInfo[eventCodeName].configurations.duration);
				if (availableDuration.length === 0) {
					this.returnToStore();
				}
				return availableDuration.map((value) => ({ value, text: this.translate(`${value}_duration`) }));
			} catch (error) {
				return [];
			}
		},
		getPresentations() {
			try {
				const data = this.productFromUrl;
				const availablePresentations = Object.keys(data.attributes.configurations.presentations);
				if (availablePresentations.length === 0) {
					this.returnToStore();
				}
				return availablePresentations.map((value) => ({ value, text: this.translate(`${value}_presentations`) }));
			} catch (error) {
				return [];
			}
		},
		getFlavor() {
			try {
				const data = this.productFromUrl;
				const availableFlavors = Object.keys(data.attributes.configurations.flavor);
				if (availableFlavors.length === 0) {
					this.returnToStore();
				}

				// Move lemon drop to the last option if it's available
				const lemonDropIndex = availableFlavors.indexOf('1001');
				if (lemonDropIndex !== -1) {
					availableFlavors.splice(lemonDropIndex, 1);
					availableFlavors.push('1001');
				}

				// Move tangerine to the first option if it's available
				const tangerineIndex = availableFlavors.indexOf('1014');
				if (tangerineIndex !== -1) {
					availableFlavors.splice(tangerineIndex, 1);
					availableFlavors.unshift('1014');
				}

				return availableFlavors.map((value) => ({ value, text: this.translate(`${value}_flavor`) }));
			} catch (error) {
				return [];
			}
		},
		getMaterialTypes() {
			try {
				const data = this.productFromUrl;
				return Object.keys(data.attributes.configurations.material_type).map((value) => ({ value, text: this.translate(`${value}_material_type`) }));
			} catch (error) {
				return [];
			}
		},
		getCombination() {
			try {
				const data = this.productFromUrl;
				const availableCombinations = Object.keys(data.attributes.configurations.combination);
				if (availableCombinations.length === 0) {
					this.returnToStore();
				}
				return availableCombinations.map((value) => ({ value, text: this.translate(`${value}_combination`) }));
			} catch (error) {
				return [];
			}
		},
		getProductSku() {
			try {
				const data = this.productFromUrl;
				let sku = [this.mainProductData.sku];
				if (this.selectedProduct.color) {
					const colorInfo = data.attributes.configurations.color;
					const selectedColor = Object.keys(colorInfo).find((color) => colorInfo[color].code_name === this.selectedProduct.color);
					sku.push(selectedColor);
				}
				if (this.selectedProduct.size) {
					sku.push(this.selectedProduct.size);
				}
				if (this.selectedProduct.format) {
					sku.push(this.selectedProduct.format);
				}
				if (this.selectedProduct.event_type) {
					sku.push(this.selectedProduct.event_type);
				}
				if (this.selectedProduct.duration) {
					sku.push(this.selectedProduct.duration);
				}
				if (this.selectedProduct.presentations) {
					sku.push(this.selectedProduct.presentations);
				}
				if (this.selectedProduct.flavor) {
					sku.push(this.selectedProduct.flavor);
				}
				if (this.selectedProduct.material_type) {
					sku.push(this.selectedProduct.material_type);
				}
				if (this.selectedProduct.combination) {
					sku.push(this.selectedProduct.combination);
				}
				if (this.selectedProduct.combo.length) {
					const skus = {};
					this.selectedProduct.combo.forEach((combo, index) => {
						if (combo) {
							skus[[this.multipleOptions[index].sku, combo].join('-')] = this.selectedProduct.quantity;
						}
					});
					return skus;
				}
				sku = sku.join('-');
				return { [sku]: this.selectedProduct.quantity };
			} catch (error) {
				return [];
			}
		},
		productOutOfStock() {
			try {
				const options = this.getProductOptions(this.getProductSku);
				return options.outOfStock;
			} catch (error) {
				return false;
			}
		},
		productSoldOut() {
			try {
				const options = this.getProductOptions(this.getProductSku);
				return options.soldOut;
			} catch (error) {
				return false;
			}
		},
		setMaxQty() {
			try {
				const sku = Object.keys(this.getProductSku)[0];
				const maxSku = this.PRODUCT_SKU_MAX_QTY[sku];
				if (maxSku) {
					return maxSku;
				}
				return this.PRODUCT_MAX_QTY[this.selectedProduct.format] ? this.PRODUCT_MAX_QTY[this.selectedProduct.format] : this.MAX_QTY;
			} catch (error) {
				return this.MAX_QTY;
			}
		},
		isForIndividualPurchase() {
			try {
				const data = this.productFromUrl;
				const options = this.getProductOptions(this.getProductSku);
				return options.individualPurchase || data.attributes.individual_purchase;
			} catch (error) {
				return false;
			}
		},
		isNotForIndividualPurchase() {
			try {
				const data = this.productFromUrl;
				const options = this.getProductOptions(this.getProductSku);
				return options.notIndividualPurchase || data.attributes.not_individual_purchase;
			} catch (error) {
				return false;
			}
		},
		shippingDateAlert() {
			try {
				const data = this.productFromUrl;
				const options = this.getProductOptions(this.getProductSku);
				return options.customShippingDate || data.attributes.custom_shipping_date;
			} catch (error) {
				return false;
			}
		},
		productsMissingForCombo() {
			try {
				return this.requiredSelect - this.selectedProduct.combo.reduce((accumulator, item) => accumulator + (typeof item !== 'undefined'), 0);
			} catch (error) {
				return 0;
			}
		},
		isComboComplete() {
			return this.productsMissingForCombo === 0;
		},
		isCombo() {
			return this.requiredSelect > 1;
		},
		disableAddBtn() {
			return this.addProductsLoading || (this.isCombo && !this.isComboComplete) || this.hasSelectedOutOfStock;
		},
		hasSelectedOutOfStock() {
			let hasSelectedOutOfStock = false;
			try {
				this.selectedProduct.combo.forEach((combo, index) => {
					this.multipleOptions[index].options.forEach((option) => {
						if (option.outOfStock && option.value === combo) {
							hasSelectedOutOfStock = true;
						}
					});
				});
			} catch (error) {
				return hasSelectedOutOfStock;
			}
			return hasSelectedOutOfStock;
		},
		comboData() {
			const { variants, discount_amount: discount } = this.mainProductData.selectable;
			// eslint-disable-next-line no-restricted-syntax
			for (const variant of variants) {
				const selected = this.multipleOptions.filter((_, i) => this.selectedProduct.combo[i] !== undefined).map((opt) => opt.category);
				if (selected.length === variant.combination.length && selected.every((category) => variant.combination.includes(category))) {
					const price = selected.reduce((amount, _, i, categories) => amount + this.multipleOptions.find((opt) => opt.category === categories[i]).price, 0) - (discount || 0);
					return {
						title: variant.title,
						description: variant.description,
						price: `$${price.toFixed(2)} USD`,
					};
				}
			}
			return {
				price: '',
			};
		},
		computedTitle() {
			const data = this.mainProductData;
			let { code_name: title } = data;
			const customTranslateKey = this.customTranslateKey(data.sku);
			if (typeof customTranslateKey !== 'undefined' && customTranslateKey.title) {
				title = customTranslateKey.title;
			}
			return this.translate(this.isCombo ? this.comboData.title ?? title : title);
		},
		computedPresentation() {
			const data = this.mainProductData;
			let { presentation } = data;
			const customTranslateKey = this.customTranslateKey(data.sku);
			if (typeof customTranslateKey !== 'undefined' && customTranslateKey.presentation) {
				presentation = customTranslateKey.presentation;
			}
			return this.translate(presentation);
		},
		computedPrice() {
			return this.isCombo ? this.comboData.price ?? this.comboPrice : this.mainProductPrice;
		},
		computedPromoPrice() {
			return '';
		},
		computedPriceInfo() {
			const { sku } = this.mainProductData;
			const customTranslateKey = this.customTranslateKey(sku);
			if (typeof customTranslateKey !== 'undefined' && customTranslateKey.priceInfo) {
				return this.translate(customTranslateKey.priceInfo);
			}
			if (Object.keys(this.monthlyPaymentInfo).length > 0) {
				return this.monthlyPaymentInfo.installments.qty > 0 ? this.translate('velovita_xlr8_2022_installments_info', {
					upfront: this.monthlyPaymentInfo.upfront,
					installments: this.monthlyPaymentInfo.installments.qty,
					installment_amount: this.monthlyPaymentInfo.installments.amount,
					installments_text: this.translate(this.monthlyPaymentInfo.installments.qty > 1 ? 'installments' : 'installment'),
				}) : '';
			}
			return '';
		},
		feesInfo() {
			try {
				if (Object.keys(this.mainProductData.children).length > 0) {
					const sku = Object.keys(this.getProductSku)[0];
					return this.mainProductData.children[sku].fees;
				}
				return this.mainProductData.fees;
			} catch (e) {
				// Do nothing
			}

			return null;
		},
		computedTabInfo() {
			const data = this.mainProductData;
			let { description } = data;
			const customTranslateKey = this.customTranslateKey(data.sku);
			if (typeof customTranslateKey !== 'undefined' && customTranslateKey.description) {
				if (customTranslateKey.description.translated) {
					return { details: customTranslateKey.description.translated };
				}
				description = customTranslateKey.description;
			}
			const tabs = { details: this.translate(this.isCombo ? this.comboData.description ?? description : description) };
			if (this.showReviews) {
				tabs.reviews = '';
			}
			return tabs;
		},
		comboPrice() {
			return this.rangePrice;
		},
		rangePrice() {
			const { variants, products, discount_amount: discount } = this.mainProductData.selectable;
			const prices = variants
				.filter((variant) => variant.combination.every((combo) => this.multipleOptions.some((opt) => opt.category === combo)))
				.filter((variant) => variant.combination.filter((combo) => !(products[this.country] ?? products.default).includes(combo)).length === 0)
				.map((variant) => variant.combination.reduce((amount, _, i, categories) => amount + (this.multipleOptions.find((opt) => opt.category === categories[i])?.price ?? 0), 0) - discount)
				.filter((value, i, values) => values.indexOf(value) === i)
				.sort((a, b) => a - b);
			return prices.length > 0 ? `$${prices[0].toFixed(2).concat(prices.length > 1 ? ` - $${prices[prices.length - 1].toFixed(2)}` : '')} USD` : this.mainProductPrice;
		},
		minPurchaseAlert() {
			try {
				const data = this.productFromUrl;
				const options = this.getProductOptions(this.getProductSku);

				return options.minPurchaseQty.restricted || data.attributes.minimun_purchase_qty.restricted;
			} catch (error) {
				return false;
			}
		},
		minPurchaseAlertMsg() {
			try {
				const data = this.productFromUrl;
				const options = this.getProductOptions(this.getProductSku);

				if (options.minPurchaseQty.restricted) {
					return options.minPurchaseQty.message_code_name;
				}
				return this.translate(data.attributes.minimun_purchase_qty.message_code_name, {
					qty: data.attributes.minimun_purchase_qty.min_qty,
				});
			} catch (error) {
				return '';
			}
		},
		monthlyPaymentInfo() {
			try {
				return this.mainProductData.children[Object.keys(this.getProductSku)[0]].monthly_payment_info;
			} catch (error) {
				return {};
			}
		},
		themeColors() {
			const { productName } = this.$route.params;
			const theme = PRODUCT_THEME[productName.toLowerCase()] ?? PRODUCT_THEME.default;

			return {
				'--theme-primary-color': theme.color,
				'--theme-primary-color-hover': theme.hoverColor,
				'--theme-primary-color-shadow': theme.focusColor,
			};
		},
	},
	watch: {
		async country() {
			this.initProductInfo();
		},
		setMaxQty(value) {
			if (this.selectedProduct.quantity > value) this.selectedProduct.quantity = value;
		},
		language(value) {
			this.ticketFlyerUrl = this.ticketFlyerImages[value];
		},
		getProductId(id) {
			if (id && typeof this.productReviewsCache[id] === 'undefined') {
				this.getProductReviews(id);
			}
		},
		sortCol() {
			this.getProductReviews(this.getProductId);
		},
		'selectedProduct.material_type': {
			handler(materialType) {
				if (typeof materialType !== 'undefined') {
					// eslint-disable-next-line no-restricted-globals
					history.pushState(null, null, `?material_type=${materialType}`);
				}
			},
		},
		'selectedProduct.flavor': {
			handler(flavor) {
				if (typeof flavor !== 'undefined') {
					// eslint-disable-next-line no-restricted-globals
					history.pushState(null, null, `?flavor=${flavor}`);
				}
			},
		},
		'selectedProduct.presentations': {
			handler(presentations) {
				if (typeof presentations !== 'undefined') {
					// eslint-disable-next-line no-restricted-globals
					history.pushState(null, null, `?presentations=${presentations}`);
				}
			},
		},
		'selectedProduct.format': {
			handler(format) {
				if (typeof format !== 'undefined') {
					// eslint-disable-next-line no-restricted-globals
					history.pushState(null, null, `?format=${format}`);
				}
			},
		},
		'selectedProduct.color': {
			handler(color) {
				if (typeof color !== 'undefined') {
					if (!this.selectedProduct.size) {
						// eslint-disable-next-line no-restricted-globals
						history.pushState(null, null, `?color=${color}`);
						return;
					}
					// eslint-disable-next-line no-restricted-globals
					history.pushState(null, null, `?color=${color}&size=${this.selectedProduct.size}`);
				}
			},
		},
		'selectedProduct.size': {
			handler(size) {
				if (typeof size !== 'undefined') {
					if (!this.selectedProduct.color) {
						// eslint-disable-next-line no-restricted-globals
						history.pushState(null, null, `?size=${size}`);
						return;
					}
					// eslint-disable-next-line no-restricted-globals
					history.pushState(null, null, `?size=${size}&color=${this.selectedProduct.color}`);
				}
			},
		},
		'selectedProduct.event_type': {
			handler(eventType) {
				if (typeof eventType !== 'undefined') {
					if (!this.selectedProduct.duration) {
						// eslint-disable-next-line no-restricted-globals
						history.pushState(null, null, `?event_type=${eventType}`);
						return;
					}
					// eslint-disable-next-line no-restricted-globals
					history.pushState(null, null, `?event_type=${eventType}&duration=${this.selectedProduct.duration}`);
				}
			},
		},
		'selectedProduct.duration': {
			handler(duration) {
				if (typeof duration !== 'undefined') {
					if (!this.selectedProduct.event_type) {
						// eslint-disable-next-line no-restricted-globals
						history.pushState(null, null, `?duration=${duration}`);
						return;
					}
					// eslint-disable-next-line no-restricted-globals
					history.pushState(null, null, `?duration=${duration}&event_type=${this.selectedProduct.event_type}`);
				}
			},
		},
	},
	mounted() {
		EventBus.$on('isReplicatedBarShown', (shown) => {
			this.topOffset = shown && !this.$user.auth() ? 55 : 0;
		});
		if (!this.showPublicStore) {
			this.$router.replace({ name: 'Store' });
			return;
		}
		if (this.earlyLaunch) {
			this.returnToStore();
			return;
		}
		this.initProductInfo();
		this.ticketFlyerUrl = this.ticketFlyerImages[this.language];
	},
	beforeDestroy() {
		EventBus.$off('isReplicatedBarShown');
	},
	methods: {
		createReview() {
			this.reviewInfo.product_id = this.getProductId;
			this.reviewInfo.product_image = this.imgCarousel[this.getGroupToShow][0].image;
			this.reviewInfo.product_name = this.translate(this.mainProductData.children[this.getGroupToShow].code_name);
			this.reviewInfo.rating = 5;
			this.reviewInfo.title = '';
			this.reviewInfo.comment = '';
			this.showReviewModal = true;
		},
		getProductReviews(id, page = 1) {
			this.productReviews.getReviewsByProduct(id, { sortCol: this.sortCol, page }).then((response) => {
				this.$set(this.productReviewsCache, id, response);
				this.$set(this.productReviewsSummaryCache, id, {
					average_rating: this.productReviews.data.response.data.meta.average_rating,
					total: this.productReviews.data.response.data.meta.total,
					user_pending_review: this.productReviews.data.response.data.meta.user_pending_review,
				});
			});
		},
		initProductInfo() {
			const { productName } = this.$route.params;
			if (productName === 'Tuun' && this.$user.auth() && this.$can('pickup_store', 'view')) {
				this.returnToStore();
			}

			// Temporarily hide combos in Canada due to wrong totals behavior in Magento
			if (this.country === 'CA' && ['Combo', 'Tri-Pack', 'Quad-Pack', 'Quad-Pack-Tuun', 'Penta-Pack'].includes(this.$route.params.productName)) {
				return this.$router.push({ name: this.getRedirectName('Store') });
			}

			return this.mainProduct.getProductDetails(productName).then((response) => {
				const { category_code: categoryCode, sku, selectable } = response.attributes;

				if (categoryCode === 'swag' && this.$user.auth() && this.$can('pickup_store', 'view')) {
					this.returnToStore();
				}

				if (!this.$user.auth() && NON_AUTH_HIDDEN_PRODUCTS.includes(sku)) {
					this.$router.push({ name: this.getRedirectName('Store') });
				}

				// For combos
				if (selectable) {
					this.comboLoading = true;
					this.requiredSelect = selectable.required_select;
					const products = selectable.products[this.country] ?? selectable.products.default;
					this.comboProduct.getManyProductDetails({ productNames: products.join(',') }).then((allProducts) => {
						this.multipleOptions = allProducts.map((res) => {
							const type = typeof res.attributes.configurations.flavor !== 'undefined' ? 'flavor' : 'material_type';
							return {
								sku: res.attributes.sku,
								category: res.attributes.decoded_name,
								price: res.attributes.price_amount,
								title: this.translate(res.attributes.code_name),
								options: Object.keys(res.attributes.configurations[type])
									.filter((value) => !(value === 'RG' && res.attributes.sku === 'TN')) // Hide Rose Gold in this combos
									.map((value) => ({
										value,
										text: this.translate(`${value}_${type}`),
										outOfStock: res.attributes.children[`${res.attributes.sku}-${value}`].out_of_stock,
									})),
							};
						});
						this.multipleOptions.sort((a, b) => {
							const indexA = products.indexOf(a.category);
							const indexB = products.indexOf(b.category);

							return indexA - indexB;
						});
					}).finally(() => {
						this.comboLoading = false;
					});
				}
				// End for combos

				this.selectInitialConfiguration();

				return this.$user.auth() ? this.relatedProducts.getProducts(this.getStoredCountry(), categoryCode) : [];
			}).catch((error) => {
				if (this.NOT_FOUND.includes(error.status)) this.$router.push({ name: this.getRedirectName('Store') });
			});
		},
		selectInitialConfiguration() {
			const {
				flavor, material_type: materialType, presentations, format, color, size, event_type: eventType, duration,
			} = this.$route.query;
			this.selectedProduct.color = this.getProductColors.find((item) => item.value === color)?.value || this.getProductColors[0]?.value;
			this.selectedProduct.size = this.getProductSizes.find((item) => item.value === size)?.value || this.getProductSizes[0]?.value;
			this.selectedProduct.format = this.getProductFormats.find((item) => item.value === format)?.value || this.getProductFormats[0]?.value;
			this.selectedProduct.event_type = this.getEventType.find((item) => item.value === eventType)?.value || this.getEventType[0]?.value;
			this.selectedProduct.duration = this.getDuration.find((item) => item.value === duration)?.value || this.getDuration[0]?.value;
			this.selectedProduct.presentations = this.getPresentations.find((item) => item.value === presentations)?.value || this.getPresentations[0]?.value;
			this.selectedProduct.combination = this.getCombination[0]?.value;
			this.selectedProduct.flavor = this.getFlavor.find((item) => item.value === flavor)?.value || this.getFlavor[0]?.value;
			this.selectedProduct.material_type = this.getMaterialTypes.find((item) => item.value === materialType)?.value || this.getMaterialTypes[0]?.value;
		},
		sortSizes(sizes) {
			sizes.sort((a, b) => SORTED_PRODUCT_SIZES.indexOf(a) - SORTED_PRODUCT_SIZES.indexOf(b));
		},
		getProductOptions(products) {
			let image = this.mainProductData.thumbnail;
			let outOfStock = false;
			let soldOut = false;
			let individualPurchase = false;
			let notIndividualPurchase = false;
			let customShippingDate = false;
			let minPurchaseQty = {};
			if (Object.keys(this.mainProductData.children).length) {
				const { children } = this.mainProductData;
				Object.keys(products).forEach((sku) => {
					image = children[sku].thumbnail;
					outOfStock = children[sku].out_of_stock;
					individualPurchase = children[sku].individual_purchase;
					notIndividualPurchase = children[sku].not_individual_purchase;
					customShippingDate = children[sku].custom_shipping_date;
					minPurchaseQty = children[sku].minimun_purchase_qty;
				});
			} else {
				outOfStock = this.mainProductData.out_of_stock;
				soldOut = this.mainProductData.sold_out;
			}
			const options = {
				image,
				outOfStock,
				individualPurchase,
				notIndividualPurchase,
				customShippingDate,
				minPurchaseQty,
				soldOut,
			};
			return options;
		},
		goToReviewsTab() {
			this.tabIndex = 1;
			EventBus.$emit('scrollTo', 'product-details-tabs');
		},
		async addProduct(products) {
			if (!this.addProductsLoading) {
				try {
					await this.addProducts.addCartProducts(cookie.get('cart_id'), { products, sponsor_id: this.$replicated.replicatedSponsor() });
					trackEvent(this.$gtm, 'productQtyUpdated'); // TODO: track product quantity and sku
					EventBus.$emit('updateLayoutStoreTotals');
				} catch (e) {
					if (typeof this.addProducts.errors.errors.products !== 'undefined') {
						let response = '';
						this.addProducts.errors.errors.products.forEach((item) => { response += `${item} \n`; });
						this.alert.toast('error', response, { timer: 4000 });
					} else {
						this.alert.toast('error', this.translate('default_error_message'));
					}
				}
			}
		},
		getProductPrice(product) {
			const { price, sku } = product;

			if (PRODUCT_OVERRIDE_PRICE[sku]) {
				return PRODUCT_OVERRIDE_PRICE[sku].price;
			}

			if (price !== '$0.00 USD') {
				return price;
			}

			if (PRODUCT_DEFAULT_PRICE[sku]) {
				return PRODUCT_DEFAULT_PRICE[sku].price;
			}

			return price;
		},
		returnToStore() {
			this.$router.push({ name: this.getRedirectName('Store') });
		},
		installmentsInfo() {
			return this.monthlyPaymentInfo.installments.qty > 0 ? this.translate('installments_info', {
				monthly: this.monthlyPaymentInfo.monthly,
				upfront: this.monthlyPaymentInfo.upfront,
				installments: this.monthlyPaymentInfo.installments.qty,
				installment_amount: this.monthlyPaymentInfo.installments.amount,
				installments_text: this.translate(this.monthlyPaymentInfo.installments.qty > 1 ? 'installments' : 'installment'),
			}) : '';
		},
		customTranslateKey(sku) {
			switch (sku) {
			case 'LT': {
				if (this.selectedProduct.presentations === 'BR') {
					return { description: 'bran_lite_description' };
				}
				if (this.selectedProduct.presentations === 'ZL') {
					return { description: 'zlem_lite_description' };
				}
				if (this.selectedProduct.presentations === 'BY') {
					return { description: 'byom_lite_description' };
				}
				return undefined;
			}
			case 'TN': {
				if (this.selectedProduct.material_type === 'DW') {
					return { description: 'tuun_diamond_premier_white_description' };
				}
				if (this.selectedProduct.material_type === 'DB') {
					return { description: 'tuun_diamond_premier_black_description' };
				}
				if (this.selectedProduct.material_type === 'SD') {
					return { description: 'tuun_swarovski_diamonds_description' };
				}
				return undefined;
			}
			case 'TK-VX22': {
				switch (this.selectedProduct.event_type) {
				case 'SI':
					return {
						presentation: 'velovita_xlr8_2022_presentation_1_ticket',
						description: {
							translated: this.translate('velovita_xlr8_2022_si_description', {
								price: this.computedPrice,
								installments_info: this.installmentsInfo(),
							}),
						},
					};
				case 'SE':
					return {
						presentation: 'velovita_xlr8_2022_presentation_1_ticket',
						description: {
							translated: this.translate('velovita_xlr8_2022_se_description', {
								price: this.computedPrice,
								installments_info: this.installmentsInfo(),
							}),
						},
					};
				case 'CO':
					return {
						presentation: 'velovita_xlr8_2022_presentation_2_ticket',
						description: {
							translated: this.translate('velovita_xlr8_2022_co_description', {
								price: this.computedPrice,
								installments_info: this.installmentsInfo(),
							}),
						},
					};
				case 'CE':
					return {
						presentation: 'velovita_xlr8_2022_presentation_2_ticket',
						description: {
							translated: this.translate('velovita_xlr8_2022_ce_description', {
								price: this.computedPrice,
								installments_info: this.installmentsInfo(),
							}),
						},
					};
				case 'NR':
					return {
						presentation: 'velovita_xlr8_2022_presentation_1_ticket',
						description: {
							translated: this.translate('velovita_xlr8_2022_nr_description', {
								price: this.computedPrice,
							}),
						},
					};
				default: return undefined;
				}
			}
			default: return undefined;
			}
		},
		subtitle() {
			const { productName } = this.$route.params;
			if (productName && productName === 'Uuth') {
				return 'TIME REVERSER SUPERBERRY';
			}
			return '';
		},
		getManyProductSku() {
			const data = this.productFromUrl;
			const sku = {};
			Object.keys(data?.attributes?.children ?? {}).forEach((childSku) => {
				sku[childSku] = 1;
			});
			return sku;
		},
		renderDefaultFee(fees) {
			const texts = Object.keys(fees).map((feeName) => {
				const fee = fees[feeName];
				return this.translate(`plus_${feeName}_with_value`, { value: fee.formatted_amount });
			});
			return `+ ${texts.join(' + ')}`;
		},
		feeVariationsText(variations) {
			const texts = [];
			variations.forEach((variation) => {
				texts.push(this.translate('plus_fee_variation', { fee: variation.fees.importation_fee.formatted_amount, qty: variation.required_qty }));
			});
			return texts.join(' ');
		},
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			if (vm.$user.details().hide_store) {
				vm.$router.replace({ name: 'Store' });
			}
		});
	},
};
</script>
<style scoped>
.h-55 {
	height: 55px;
}
.h-80 {
	height: 80px;
}
.custom-btn.custom-primary-btn{
	width: 150px;
	border-radius: 25px;
}

.img-max-width{
	max-width: 250px;
}
.add-all-options-btn:hover:not(:disabled) {
	opacity: 0.9;
}

.add-all-options-btn:focus {
	box-shadow: 0 0 0 0.2rem rgba(106, 165, 10, 0.5) !important;
}
</style>
<style>
#theme .vnis-custom-input-width{
	width: 40px;
}
.btn-disabled.disabled{
	background: #777777b5;
    border-color: rgba(130, 130, 130, 0.709804);
    color: #FFFFFF;
}
.btn-disabled.disabled:hover{
	background: #777777b5;
    border-color: rgba(130, 130, 130, 0.709804);
    color: #FFFFFF;
}
.bolder-title {
	-webkit-text-stroke: 1.3px;
	font-size: 1.2em;
}
#theme .btn-rounded {
	border-radius: 25px;
}
/* Tabs */
#product-details-tabs ul {
	border-bottom: 2px solid #c8ced3;
}
#product-details-tabs li a{
	padding-right: 1em;
	padding-bottom: .5em;
	margin-right: 1.5em;
}
#product-details-tabs li a.active {
	border-bottom: 3px solid #eb772f;
	border-width: thick;
}
#product-details-tabs .tab-content {
	padding: 2em 0em 2em 0em;
	border: none;
}

/* Tabs title color */
#product-details-tabs li a {
	color: var(--theme-primary-color);
}
#product-details-tabs li a.active {
	border-bottom-color: var(--theme-primary-color);
}

/* Quantity selector button color */
.vnis__button.bg-primary-alt:hover,
.vnis__button.bg-primary-alt:not(:disabled):not(.disabled):active {
	background: var(--theme-primary-color) !important;
}

/* Branded pagination buttons */
.page-item {
	&.active .page-link {
		background: var(--theme-primary-color);
	}

	.page-link {
		color: var(--theme-primary-color);

		&:focus {
			box-shadow: 0 0 0 0.2rem var(--theme-primary-color-shadow) !important;
		}
	}
}
</style>
